<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col cols="12" md="6"> </b-col>
          <!-- Search -->
          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <b-button variant="primary" @click="openMatris">
                <span class="text-nowrap">{{ $t("show") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-table
              class="user-select-none"
              :responsive="true"
              :items="rations"
              :fields="rationsFields"
              selectable
              @row-selected="selectItem"
            >
              <template #cell(isSelected)="a">
                <b-form-checkbox
                  :ref="a.item.id + 'cb'"
                  v-model="a.item.isSelected"
                  @change="selectItem(a)"
                >
                </b-form-checkbox>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal id="matris" ref="matrisModal" title="Matris" size="xl" ok-only>
      <b-card no-body class="">
        <b-form-select v-model="selectedTable">
          <b-form-select-option value="0">Hammaddeler</b-form-select-option>
          <b-form-select-option value="1">Besin Maddeleri</b-form-select-option>
          <b-form-select-option value="2">Fiyatlar</b-form-select-option>
        </b-form-select>
        <div v-if="matris" class="mh-full mt-1">
          <b-table
            :responsive="true"
            sticky-header="true"
            :no-border-collapse="true"
            :items="matris[0][Object.keys(matris[0])[selectedTable]]"
          >
          </b-table>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BButton,
  BTable,
  BModal,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";
import { getUserData } from "@/auth/utils";
import VueI18n from "@/libs/i18n";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // BBadge,
    BButton,
    BTable,
    BModal,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
  },
  data() {
    return {
      selectedTable: 0,
      selectedRations: [],
      rations: [],
      rationsFields: [
        { key: "isSelected", label: this.$t("selected") },
        { key: "code", label: this.$t("code") },
        { key: "rationname", label: this.$t("rationName") },
        {
          key: "formulaDate",
          label: this.$t("formulaDate"),
          formatter: (val) =>
            `${dayjs(val)
              .locale(`${this.$i18n.locale}`)
              .format("DD MMMM YYYY HH:mm:ss")}`,
        },
        {
          key: "fiyat",
          label: this.$t("price"),
          formatter: (val) =>
            `${val.toFixed(getUserData().pricedecimal)} ${
              getUserData().currency
            }`,
          tdClass: "text-right mw-300",
        },
      ],
      matris: null,
    };
  },
  mounted() {
    this.getRationsForCompare();
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "compareFormulas") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/",
        text: VueI18n.t("formulas"),
        active: true,
      });

      to.meta.breadcrumb.push({
        text: "compareFormulas",
        active: true,
      });
    }
    next();
  },
  methods: {
    selectItem(items) {
      if (Array.isArray(items)) {
        this.rations.forEach((item) => {
          if (items.filter((x) => x.id === item.id).length > 0) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        });
      } else {
        if (!items.rowSelected) items.selectRow();
        else items.unselectRow();
        items.item.isSelected = !items.item.isSelected;
      }
    },
    selectAll(value, type) {
      value.forEach((b) => {
        b.isselected = true;
      });
      document.querySelectorAll(`.${type}-checkbox`).forEach((b) => {
        b.checked = true;
      });
    },
    removeAll(value, type) {
      value.forEach((b) => {
        b.isselected = false;
      });
      document.querySelectorAll(`.${type}-checkbox`).forEach((b) => {
        b.checked = false;
      });
    },
    async openMatris() {
      this.selectedRations = this.rations
        .filter((x) => x.isSelected == true)
        .map((x) => x.id);

      this.matris = await this.getComparedRations(
        this.selectedRations.join(",")
      );

      //openModal
      this.$refs.matrisModal.show();
    },
    async getComparedRations(ids) {
      return await this.$store.dispatch("formulasModule/getComparedRations", {
        ids,
      });
    },
    async getRationsForCompare() {
      this.rations = await this.$store.dispatch(
        "formulasModule/getRationsForCompare",
        {
          plantid: this.$store.state.app.selectedPlantId,
        }
      );
    },
  },
};
</script>

<style>
.form-custom-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-custom-control + .form-custom-control {
  margin-top: 1em;
}

.form-custom-control--disabled {
  color: var(--form-custom-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.55em;
  height: 1.55em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 1em;
  height: 1em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0) translateX(-1em);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-custom-control-color);
  /* Windows High Contrast Mode */
  background-color: black;
}

input[type="checkbox"]:checked::before {
  transform: scale(1) translateX(0);
}

input[type="checkbox"]:disabled {
  --form-custom-control-color: var(--form-custom-control-disabled);

  color: var(--form-custom-control-disabled);
  cursor: not-allowed;
}
.checkbox-span {
  font-size: 14px;
  user-select: none;
}
.box {
  padding: 2px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.mh-full {
  min-width: calc(100vw - 300px);
  max-height: calc(100vh - 300px);
  overflow: auto;
}
.b-table-sticky-header {
  max-height: calc(100vh - 350px);
}
</style>
